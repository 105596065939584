<template>
  <ion-page :fullscreen="true">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            v-if="dynamicComponent === 'Restaurant'"
            default-href="/landing"
          ></ion-back-button>
          <ion-back-button
            v-if="dynamicComponent === 'Menu' && !complexMenu"
            default-href="/landing"
          ></ion-back-button>
          <ion-button
            v-if="dynamicComponent === 'Menu' && complexMenu"
            @click="goToRestaurants"
            size="large"
            slot="icon-only"
            class="no-ripple"
          >
            <ion-icon :icon="arrowBackOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title v-if="restaurantName">{{ restaurantName }}</ion-title>
        <ion-title v-else>
          <ion-skeleton-text animated style="width: 50%; height:40%"></ion-skeleton-text>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="main-wrapper">
        <swiper
          v-if="getBanner.length"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"
          :pagination="{
            clickable: true,
          }"
          class="main-swipper"
        >
          <template v-for="(banner, index) in getBanner" :key="index">
            <swiper-slide class="single-swipper">
              <ion-img :src="banner"></ion-img>
            </swiper-slide>
          </template>
        </swiper>
        <div v-if="!getBanner.length" class="main-swipper">
          <ion-skeleton-text animated style="width: 100%; height:100%"></ion-skeleton-text>
        </div>
        <div>
          <!-- Using dynamic components to display both major categories and menu list -->
          <transition name="component-slide" mode="out-in">
            <component
              :is="dynamicComponent"
              :currentProperties="currentProperties"
              @category-name="goToMenu"
            >
            </component>
          </transition>
        </div>
      </div>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button
          @click="openModal"
          :style="{
            '--background': getStyle.categorybackgroundcolor,
            '--box-shadow': 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
          }"
        >
          <ion-icon :icon="chatboxOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>
<script>
  import { defineAsyncComponent } from 'vue'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import SwiperCore, { Pagination, Autoplay } from 'swiper/core'
  import 'swiper/swiper-bundle.min.css'
  import { mapActions, mapGetters } from 'vuex'
  import { arrowBackOutline, chatboxOutline } from 'ionicons/icons'
  import Feedback from '../components/util/Feedback.vue'

  SwiperCore.use([Pagination, Autoplay])
  import {
    IonPage,
    IonContent,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonButton,
    IonBackButton,
    IonSkeletonText,
    IonImg,
    IonIcon,
    IonFab,
    IonFabButton,
    modalController,
  } from '@ionic/vue'
  export default {
    name: 'Home',
    components: {
      IonPage,
      IonContent,
      IonToolbar,
      IonHeader,
      IonTitle,
      IonButtons,
      IonButton,
      IonBackButton,
      IonSkeletonText,
      Swiper,
      SwiperSlide,
      IonImg,
      IonIcon,
      IonFab,
      IonFabButton,
      Restaurant: defineAsyncComponent(() => import('../pages/Restaurants.vue')),
      Menu: defineAsyncComponent(() => import('../pages/Menu.vue')),
    },
    inject: ['hotelId', 'restaurantId'],
    data() {
      return {
        dynamicComponent: '',
        arrowBackOutline,
        chatboxOutline,
        category: {
          categoryName: '',
          categoryId: '',
        },
      }
    },
    methods: {
      ...mapActions({
        restaurant: 'getRestaurant',
        sendFeedBack: 'postFeedBack',
      }),
      //   Change current component through emit from child to parent
      goToRestaurants() {
        this.dynamicComponent = 'Restaurant'
      },
      goToMenu(mainCategoryName) {
        this.category = mainCategoryName
        this.dynamicComponent = 'Menu'
      },
      async openModal() {
        const modal = await modalController.create({
          component: Feedback,
          cssClass: 'feedback-main',
          backdropDismiss: false,
          componentProps: {
            title: 'Feedback',
          },
        })
        await modal.present()
        let modalResponse = await modal.onDidDismiss()
        if (modalResponse.data) {
          let payload = {
            hotel: this.getMainIds.hotelUId,
            restaurant: this.getMainIds.restaurantUId,
            feedback: modalResponse.data,
          }
          this.sendFeedBack(payload)
        }
      },
    },
    created() {
      // Fetch all restaurant and menu data on view load
      if (!this.hotelId || !this.restaurantId) {
        return this.$router.replace({ name: 'Landing' })
      }
      this.restaurant({ hotelId: this.hotelId, restaurantId: this.restaurantId })
      this.dynamicComponent = this.changingComponent
    },
    computed: {
      ...mapGetters(['getBanner', 'getStyle', 'getMainIds']),
      complexMenu: function() {
        return this.$store.state.complexmenu
      },
      changingComponent: function() {
        let complexmenu = this.complexMenu
        let component = ''
        if (complexmenu == true) {
          component = 'Restaurant'
        } else {
          component = 'Menu'
        }
        return component
      },
      restaurantName: function() {
        return this.$store.state.restaurantName
      },
      //   Dynamically pass props to the dynamically loaded components
      currentProperties: function() {
        if (this.dynamicComponent === 'Menu' && this.complexMenu) {
          return this.category
        }
        if (this.dynamicComponent === 'Restaurant') {
          return { hotelId: this.hotelId, restaurantId: this.restaurantId }
        }
        return null
      },
    },
    watch: {
      changingComponent(params) {
        if (this.dynamicComponent != params) {
          this.dynamicComponent = params
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .component-slide-enter-active,
  .component-slide-leave-active {
    transform: translateX(0);
    transition: all 0.3s linear;
  }
  .component-slide-enter-from,
  .component-slide-leave-to {
    transform: translateX(100%);
  }
  ion-title {
    font-size: 1.12em;
  }
  .main-wrapper {
    height: 100%;
    .main-swipper {
      height: 30%;
      width: 100%;

      .single-swipper {
        height: 100%;
      }
    }
  }
</style>
