<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeFeedback" size="large" slot="icon-only" class="no-ripple">
            <ion-icon :icon="arrowBackOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="header-bg">
        <ion-img src="assets/icon/icon.png"></ion-img>
      </div>
      <div class="content ion-padding">
        <h4 class="feedback-title">How has your experience been today?</h4>
        <div class="emojiwrapper">
          <div v-for="emoji in emojis" :key="emoji.id" class="emoji">
            <ion-avatar
              @click="emojiSelected(emoji.id, emoji.value)"
              :class="{ active: emoji.id == reactionId }"
            >
              <img :src="emoji.icon" />
            </ion-avatar>
          </div>
        </div>
        <div class="comment-area">
          <h4 class="feedback-title">Do you have any thoughts you would like to share?</h4>
          <ion-item>
            <ion-textarea
              rows="4"
              placeholder="Leave a comment...."
              v-model.trim="comments"
            ></ion-textarea>
          </ion-item>
        </div>
        <div class="button-footer">
          <ion-button @click="submitFeedback">Submit</ion-button>
          <ion-button fill="outline" @click="closeFeedback">Cancel</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
  import {
    IonPage,
    IonContent,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonAvatar,
    IonItem,
    IonTextarea,
    IonImg,
    modalController,
    toastController,
  } from '@ionic/vue'
  import { arrowBackOutline } from 'ionicons/icons'
  export default {
    name: 'Feedback',
    props: {
      title: { type: String, default: 'Feedback' },
    },
    components: {
      IonPage,
      IonContent,
      IonToolbar,
      IonHeader,
      IonTitle,
      IonButtons,
      IonButton,
      IonIcon,
      IonAvatar,
      IonItem,
      IonTextarea,
      IonImg,
    },
    data() {
      return {
        arrowBackOutline,
        reactionId: null,
        reaction: null,
        comments: '',
        emojis: [
          {
            id: 1,
            icon: 'img/icons/worst.svg',
            value: 'worst',
          },
          {
            id: 2,
            icon: 'img/icons/poor.svg',
            value: 'poor',
          },
          {
            id: 3,
            icon: 'img/icons/average.svg',
            value: 'average',
          },
          {
            id: 4,
            icon: 'img/icons/good.svg',
            value: 'good',
          },
          {
            id: 5,
            icon: 'img/icons/excellent.svg',
            value: 'excellent',
          },
        ],
      }
    },
    methods: {
      async openToast(message, color) {
        const toast = await toastController.create({
          message: message,
          animated: true,
          color: color,

          duration: 2000,
        })
        return toast.present()
      },
      closeFeedback() {
        return modalController.dismiss()
      },
      submitFeedback() {
        if (this.reaction != null || this.comments != '') {
          let finalComment = this.comments ? this.comments : 'No comments'
          let finalReaction = this.reaction ? this.reaction : 'No Reaction'
          let feedback = {
            reaction: finalReaction,
            comments: finalComment,
          }
          modalController.dismiss(feedback)
          return this.openToast('Thank you for your feedback', 'success')
        } else {
          this.openToast('No reaction or comments provided', 'danger')
        }
      },
      emojiSelected(id, name) {
        this.reactionId = id
        this.reaction = name
      },
    },
  }
</script>
<style lang="scss" scoped>
  .header-bg {
    position: relative;
    top: 0;
    height: 20%;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;
    ion-img {
      height: 60%;
      width: 60%;
    }
  }
  .emojiwrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 0.2rem;
    .emoji {
      flex: 1 0;
      margin: 0.5rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      ion-avatar {
        height: 3rem;
        width: 3rem;
        background: #f5f4f7;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        transition: 1s linear ease;
        img {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
  }
  .active {
    background: var(--ion-color-warning) !important;
  }
  .feedback-title {
    font-size: 0.9em;
    // margin: 1rem 0.2rem;
  }
  .button-footer {
    display: flex;
    margin: 1rem 0;
    ion-button {
      flex: 1 0;
    }
  }
</style>
